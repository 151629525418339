<template>
  <div class="d-flex justify-center align-center" style="height: 100vh">
    <v-sheet class="login-card" width="550px">
      <template v-if="isFetching">
        <v-avatar size="70" color="blue lighten-4">
          <v-icon size="60" color="warning">mdi-dots-horizontal</v-icon>
        </v-avatar>
        <div class="text-h6 mb-2 mt-5 ems-blue-grey-2--text">
          正在取得 LINE 綁定結果，請稍後
        </div>
      </template>
      <template v-else>
        <v-icon v-if="result" size="80" color="success"
          >mdi-check-circle</v-icon
        >
        <v-icon v-else size="80" color="warning">mdi-alert-circle</v-icon>
        <div class="text-h5 font-weight-bold mt-5 ems-blue-grey-2--text">
          綁定 LINE 帳號{{ result ? '成功' : '失敗' }}
        </div>
        <div
          class="text-body-1 mt-4 ems-blue-grey-2--text"
          v-html="
            result
              ? `掃描下方 QR Code 加入官方 LINE
        帳號<br />您將可透過 LINE 收到系統告警`
              : `LINE 帳號綁定失敗，請重新設定`
          "
        ></div>
        <v-btn
          v-if="!result"
          class="mt-10 white--text"
          block
          large
          color="ems-blue-grey-2"
          type="submit"
          to="/line/settings"
          >前往設定 LINE 帳號綁定</v-btn
        >
        <v-img
          v-if="result"
          max-width="200"
          width="100%"
          class="ma-auto mt-4"
          :src="lineOfficialQRCodeUrl"
        >
          <template v-slot:placeholder>
            <v-sheet
              height="200"
              max-width="200"
              width="100%"
              class="d-flex justify-center align-center bg-transparent"
              style="border: 1px solid #aaa"
            >
              <v-progress-circular
                v-if="isFetchingLineOfficialQRCodeUrl"
                color="grey-lighten-4"
                indeterminate
              ></v-progress-circular>
              <div v-else-if="!lineOfficialQRCodeUrl">載入 QR Code 失敗</div>
            </v-sheet>
          </template>
        </v-img>
      </template>
      <v-btn
        class="mt-3 mb-5"
        text
        block
        large
        color="ems-blue-grey-2"
        prepend-icon="mdi-keyboard-backspace"
        to="/"
        >返回首頁</v-btn
      >
    </v-sheet>
  </div>
</template>

<script>
import LineMessaging from '@/api/LineMessaging'

export default {
  name: 'LineCallback',
  props: {
    code: {
      type: String
    },
    state: {
      type: String
    }
  },
  async mounted() {
    await this.callbackHandler()
  },
  data() {
    return {
      isFetching: false,
      result: false,
      lineOfficialQRCodeUrl: '',
      isFetchingLineOfficialQRCodeUrl: false
    }
  },
  methods: {
    async callbackHandler() {
      this.isFetching = true
      try {
        await LineMessaging.callbackHandler(this.code, this.state)
        this.result = true
        if (this.result) {
          this.getLineOfficialQRCodeUrl()
        }
      } catch (error) {
        console.error(error)
      }
      this.isFetching = false
    },
    async getLineOfficialQRCodeUrl() {
      this.isFetchingLineOfficialQRCodeUrl = true
      try {
        const { data } = await LineMessaging.getLineOfficialQRCodeUrl()
        this.lineOfficialQRCodeUrl = data
      } catch (error) {
        console.error(error)
      }
      this.isFetchingLineOfficialQRCodeUrl = false
    }
  }
}
</script>

<style lang="scss" scoped>
.login-card {
  backdrop-filter: blur(10px);
  background-color: rgb(232, 235, 237, 0.3) !important;
  border: 2px solid white !important;
  border-radius: 24px !important;
  text-align: center;
  padding: 60px;
  width: 460px;
  max-width: calc(100% - 40px);
}
</style>
